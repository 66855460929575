import './UserInput.css'
import TextareaAutosize from 'react-textarea-autosize'
import { useState } from 'react'
import send from '../images/send.svg'

export default function UserInput ({ addQuestion, disabled }) {
  const [question, setQuestion] = useState('')
  const [inputMode, setInputMode] = useState('none')

  const handleChange = ({ target }) => {
    setQuestion(target.value)
  }

  const handleInputClick = () => {
    if (inputMode === 'none') {
      setInputMode('text')
    }
  }
  const handleKeyDown = event => {
    const { key } = event
    if (key === 'Enter') {
      event.preventDefault()
      handleClick()
    }
  }

  const handleClick = () => {
    if (question) {
      addQuestion(question)
      setQuestion('')
      setInputMode('none')
    }
  }

  return (
    <div className="input-container">
      <TextareaAutosize className="input" key={disabled ? 'disabled' : 'enabled'} value={question} autoFocus={true} onChange={handleChange}
                        onKeyDown={handleKeyDown} disabled={disabled} inputMode={inputMode} onClick={handleInputClick}/>
      <img className="send" src={send} alt="send" onClick={handleClick}/>
    </div>
  )
}
