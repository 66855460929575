import axios from 'axios'

const apiBaseUrl = 'https://greengpt-api-dev.futurestash.com'

export async function getResponse (chatId, question, questionHistory, answerHistory, surveyMode) {
  const chatHistory = transformChatHistory(questionHistory, answerHistory)
  const axiosResponse = await axios.post(`${apiBaseUrl}/get_response`, {
    chat_id: chatId,
    query: question,
    chat_history: chatHistory,
    survey_mode: surveyMode
  })
  return {
    queryId: axiosResponse.data.query_id,
    answer: axiosResponse.data.greenGPT,
    surveyMode: axiosResponse.data.survey_mode
  }
}

export async function saveFeedback (chatId, queryId, vote, feedback, questionHistory, answerHistory) {
  const chatHistory = transformChatHistory(questionHistory, answerHistory)
  const payload = {
    chat_id: chatId,
    query_id: queryId,
    vote_feedback: vote === 'good' ? 1 : -1,
    text_feedback: feedback,
    chat_history: chatHistory
  }
  await axios.post(`${apiBaseUrl}/user_feedback`, payload)
}

function transformChatHistory (questionHistory, answerHistory) {
  const chatHistory = []
  questionHistory.forEach((question, index) => {
    chatHistory.push({ role: 'user', content: question })
    if (answerHistory.length - 1 >= index) {
      chatHistory.push({ role: 'assistant', content: answerHistory[index] })
    }
  })
  return chatHistory
}
