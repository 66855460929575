import './Disclaimer.css'
import Modal from '../Modal/Modal'

export default function Disclaimer ({ setShowDisclaimer }) {
  const handleConfirm = async () => {
    setShowDisclaimer(false)
  }

  const disclaimer = 'You are using the beta test version of chat.green, a generative AI chatbot designed to help you adopt a low-carbon lifestyle and discover cash incentives and other financial benefits of climate-friendly living.\nDeveloped by Future, chat.green incorporates tools from OpenAI and leverages Future\'s in-house research. Future is a climate-focused financial technology company offering rewards for everyday low-carbon purchases and paying members for reduced emissions.\nchat.green may occasionally produce inaccurate information about people, places, or facts and should not be considered a source of financial, legal or medical advice. Any chat history or other interactions with this beta version of chat.green may be used to further enhance and refine the AI model.'

  return (
    <Modal>
      <div className="disclaimer-container">
        <p className="disclaimer">
          {disclaimer}
        </p>
        <button className="confirm" onClick={handleConfirm}>Confirm</button>
      </div>
    </Modal>
  )
}
