import './ChatBubble.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons'
import * as fas from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import Modal from '../Modal/Modal'

export default function ChatBubble ({ role, message, loading, showFeedback, selections, setQuestions, saveFeedback }) {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedVote, setSelectedVote] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [feedback, setFeedback] = useState('')
  const handleVote = option => {
    if (!selectedVote) {
      setSelectedVote(option)
      setShowModal(true)
    }
  }

  const handleChange = ({ target }) => {
    setFeedback(target.value)
  }

  const handleClose = async () => {
    setShowModal(false)
    await saveFeedback(selectedVote, '')
  }

  const handleSubmit = async () => {
    setShowModal(false)
    await saveFeedback(selectedVote, feedback)
  }

  if (role === 'user') {
    return <div className={`chat-bubble ${role}`}>{message}</div>
  }
  return (
    <>
      <div className="bot-container" style={!feedback ? { marginBottom: '10px' } : {}}>
        <div className="icon"/>
        <div className={`chat-bubble ${role}`}>
          {loading ? <><span/><span/><span/></> : message.replace(/\n/g, '\n\n')}
        </div>
      </div>
      {
        selections &&
        <>
          {selections.map((selection, index) => <div className={`option ${selectedOptions.includes(index) && 'selected-option'}`}
                                                     key={`option-${index}`}
                                                     style={index === selections.length - 1 ? { marginBottom: '20px' } : {}}
                                                     onClick={() => {
                                                       if (!selectedOptions.includes(index)) {
                                                         let question
                                                         switch (index) {
                                                           case 0:
                                                             question = 'What tax incentives are available in the U.S. to lower my carbon footprint?'
                                                             break
                                                           case 1:
                                                             question = 'What\'s the average carbon footprint in the U.S.?'
                                                             break
                                                           case 2:
                                                             question = 'How much could I save at the pump when I drive electric?'
                                                             break
                                                           case 3:
                                                             question = 'What fashion brands offer great secondhand options?'
                                                             break
                                                           case 4:
                                                             question = 'How can I save a ton of carbon?'
                                                             break
                                                           default:
                                                             question = null
                                                         }
                                                         setSelectedOptions([...selectedOptions, index])
                                                         setQuestions(question)
                                                       }
                                                     }
                                                     }>{selection}</div>)}
        </>
      }
      {
        !loading && showFeedback &&
        <div className="feedback-container">
          <FontAwesomeIcon icon={selectedVote === 'good' ? fas.faThumbsUp : faThumbsUp}
                           style={selectedVote ? { cursor: 'default' } : {}}
                           onClick={() => handleVote('good')}/>
          <FontAwesomeIcon icon={selectedVote === 'bad' ? fas.faThumbsDown : faThumbsDown}
                           style={selectedVote ? { cursor: 'default' } : {}}
                           onClick={() => handleVote('bad')}/>
        </div>
      }
      {
        showModal &&
        <Modal>
          <div className="modal-container">
            <div className="modal-header">
              <h3>Provide additional feedback</h3>
              <FontAwesomeIcon icon={fas.faXmark} onClick={handleClose}/>
            </div>
            <textarea className="feedback-input" autoFocus value={feedback} onChange={handleChange}/>
            <button className="submit" onClick={handleSubmit}>Submit</button>
          </div>
        </Modal>
      }
    </>
  )
}
